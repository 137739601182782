/* eslint-disable max-len */
export const environment = {
  firebaseConfig: {
    apiKey: 'AIzaSyDXO7SaxWZaul_A9qBM0zm_gUfSRK0WEgg',
    authDomain: 'tierra-cervecera-d428d.firebaseapp.com',
    projectId: 'tierra-cervecera-d428d',
    storageBucket: 'tierra-cervecera-d428d.appspot.com',
    messagingSenderId: '1068207094662',
    appId: '1:1068207094662:web:20cf2504bd47b182f18a07',
    measurementId: 'G-MGG199B6D2'
  },
  recaptcha: {
    siteKey: '6LfEOEcfAAAAAKAkKyxHylH3c4dB3WH8yV2ZBXs1'
  },
  production: true
};
